// @ts-nocheck
import cogoToast from "cogo-toast";
import { currencies } from "../constants/currency";

export const getLocation = ({
  area = "",
  city = "",
  district = "",
  street = "",
}: {
  area: string;
  city: string;
  district: string;
  street: string;
}): string =>
  `${city}${district?.length > 0 ? `, ${district}` : ""}${
    street?.length > 0 ? `, ${street}` : ""
  }`;

export const getIdFromUrl = () => window.location.search?.split("=")[1] ?? null;

export const getParams = (name: string) => {
  const params = window.location.search?.replace("?", "").split("&");
  const param = params?.find((p) => p.includes(`${name}=`))?.split("=")?.[1];
  return param;
};

export const getCurrencySymbol = (currencyTitle: string): string =>
  currencies.find((c) => c.title === currencyTitle)?.symbol ?? "";

export const addZero = (num: number): string | number =>
  num < 10 ? `0${num}` : num;

export const getHours = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const hours = addZero(date.getHours());
  const minutes = addZero(date.getMinutes());

  return `${hours}:${minutes}`;
};

export const handleDownload = (fileLink: string) => {
  var link = document.createElement("a");
  link.setAttribute("download", "file");
  link.href = fileLink;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const removeDublicats = (array: any) => {
  const updatedArray: any = [];

  array.forEach((element: any) => {
    if (!updatedArray.find((e: any) => e.id === element.id)) {
      updatedArray.push(element);
    }
  });

  return updatedArray;
};

function detectMob() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window?.opera);
  return check;
}

export const checkIsBrowserSupportTouch = () => {
  const isMobile = detectMob();
  const isSupportTouch =
    "ontouchstart" in window ||
    (window.DocumentTouch && document instanceof DocumentTouch);

  if (isMobile && !isSupportTouch) {
    cogoToast.error("Оновіть браузер", { hideAfter: 5 });
  }
};

export const handleToFormData = (data: any): any => {
  const formData = new FormData();

  Object.entries(data).forEach((field) => {
    if (Array.isArray(field[1])) {
      field[1]?.forEach((f) => {
        formData.append(`${field[0]}[]`, f);
      });
    } else {
      formData.append(field[0], field[1]);
    }
  });

  return formData;
};
